


















































































































































































































































import {
  LimitedSubscriptionConfig,
  LimitedSubscriptionNotify,
  LimitedSubscriptionType
} from '@/includes/types/Channel.types'
import formatTime from '@/includes/formatTime'
import { InputSetups } from '@/mixins/input-setups'
import ActivationActionData
  from 'piramis-base-components/src/components/BotFeedback/PrivateMessageSettings/components/ActivationActionData.vue'
import {
  UserActivationAction,
  UserActivationActionFlow,
  UserActivationActionMessage,
  UserActivationActionType
} from '@/includes/types/PmConfig.types'
import AddEntityCard from '@/components/AddEntityCard.vue'
import TriggerCardAction from '@/components/TriggerCardAction.vue'
import TypeSelect from '@/components/TypeSelect.vue'
import { warningNotification } from '@/includes/services/NotificationService'
import EditorButtonsHelpView from '@/components/EditorButtonsHelpView.vue'
import PlaceholdersMixin from '@/mixins/PlaceholdersMixin'
import SaveChannelConfig from '@/components/SaveChannelConfig.vue'

import { UseFields } from 'piramis-base-components/src/components/Pi'
import { SelectOption } from 'piramis-base-components/src/logic/types'
import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'
import EmptyData from 'piramis-base-components/src/components/EmptyData/EmptyData.vue'
import { StepUnit } from 'piramis-base-components/src/components/PeriodSimplifier/types'
import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'
import HighlightAnchor from 'piramis-base-components/src/components/HighlightAnchor.vue'
import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'
import MultiMessageEditorWithMediaInput
  from 'piramis-base-components/src/components/Pi/fields/NewMultiMessageEditorWithMediaInput/MultiMessageEditorWithMediaInput.vue'
import ExtendedHelpMessage from 'piramis-base-components/src/components/ExtendedHelpMessage.vue'
import ModelSetter from 'piramis-base-components/src/Mixins/ModelSetter'

import { Component, Mixins, Watch } from 'vue-property-decorator'
import { cloneDeep, snakeCase } from 'lodash'
import { Guid } from 'guid-typescript'
import Vue from 'vue'

type NotifyStruct = {
  time: number
  guid: string
  action: UserActivationAction
}

@Component({
  components: {
    SaveChannelConfig,
    PageTitle,
    ConfigField,
    ActivationActionData,
    EmptyData,
    AddEntityCard,
    TriggerCardAction,
    TypeSelect,
    HighlightAnchor,
    ExtendedHelpMessage,
    MultiMessageEditorWithMediaInput
  },
  data() {
    return {
      StepUnit,
      LimitedSubscriptionType,
      EditorButtonsHelpView,
      EntityTypes
    }
  }
})
export default class ChannelSubscription extends Mixins(ModelSetter, UseFields, InputSetups, PlaceholdersMixin) {

  subscriptionType = LimitedSubscriptionType.None

  @Watch('subscriptionType', { immediate: true })
  onSubscriptionTypeChange(value: LimitedSubscriptionType) {
    if (value === LimitedSubscriptionType.Comments && !this.subscriptionConfig.comments_subscription_warning) {
      this.$set(this.subscriptionConfig, 'comments_subscription_warning', [])
    }
  }

  subscriptionConfig = {} as LimitedSubscriptionConfig

  actionModalOpen = false

  frontendNotifies: Array<NotifyStruct> = []

  get actionOptions(): Array<SelectOption> {
    return [
      {
        value: UserActivationActionType.Message,
        label: `field_${ UserActivationActionType.Message.toLowerCase() }_action_type`
      },
      {
        value: UserActivationActionType.Flow,
        label: `field_${ UserActivationActionType.Flow.toLowerCase() }_action_type`
      },
    ]
  }

  get subscriptionTypeOptions(): Array<SelectOption> {
    return Object.keys(LimitedSubscriptionType).map(k => ({
      label: this.$t(`field_${ snakeCase(k) }_title`).toString(),
      value: k,
    }))
  }

  get subscriptionTypeAlertMessage() {
    if (this.subscriptionType === LimitedSubscriptionType.Channel) {
      return this.$t('subscription_type_channel_alert_message').toString()
    } else if (this.subscriptionType === LimitedSubscriptionType.Comments) {
      return this.$t('subscription_type_comments_alert_message').toString()
    } else if (this.subscriptionType === LimitedSubscriptionType.Content) {
      return this.$t('subscription_type_content_alert_message').toString()
    } else if (this.subscriptionType === LimitedSubscriptionType.Sponsorship) {
      return this.$t('subscription_type_sponsorship_alert_message').toString()
    } else {
      return ''
    }
  }

  setHighlight(guid: string): void {
    const item: Vue = this.$refs[`trigger-${ guid }`]![0]
    item.$el.classList.add('notify-invalid')

    setTimeout(() => {
      item.$el.classList.remove('notify-invalid')
    }, 7000)
  }

  validateNotifies(): Promise<void> {
    return new Promise((resolve, reject) => {
      const hasDuplicate = this.frontendNotifies.some((n, _, array) => {
        const notifiesDoubles = array.filter(fr => fr.time === n.time)

        if (notifiesDoubles.length > 1) {
          notifiesDoubles.map(n => n.guid).forEach(this.setHighlight)
          return true
        }

        return false
      })

      const messageIsNotConfigured = this.frontendNotifies.some((n) => {
        if (n.action.type === UserActivationActionType.Message) {
          const condition = !n.action.variants.length || !n.action.variants[0].text.trim()

          if (condition) {
            this.setHighlight(n.guid)

            return true
          }

          return false
        }
      })

      const timeIsNotConfigured = this.frontendNotifies.some((n) => {
        if (n.time < StepUnit.Hour) {
          this.setHighlight(n.guid)
          return true
        }
      })

      if (hasDuplicate) {
        reject(this.$t('notifies_non_unique').toString())
      } else if (messageIsNotConfigured) {
        reject(this.$t('message_is_not_configured_error').toString())
      } else if (timeIsNotConfigured) {
        reject(this.$t('min_notify_time_is_hour').toString())
      }

      resolve()
    })
  }

  saveChannelConfig(): void {
    this.validateNotifies()
      .then(() => {
        this.subscriptionConfig.notify = this.setRawNotifies()

        this.$store.commit('pi/EXEC', {
          'fn': () => {
            this.$store.state.channelsState.activeChannel!.config.limited_subscription_config = this.subscriptionConfig
            this.$store.state.channelsState.activeChannel!.config.limited_subscription_type = this.subscriptionType
          },
        })

        this.$store.dispatch('save_active_channel_config', this.$route)
        this.setLocalSubscriptionConfig()
      })
      .catch((e) => {
        warningNotification(e)
      })
  }

  removeItem(item: NotifyStruct): void {
    this.frontendNotifies = this.frontendNotifies.filter(n => n.guid !== item.guid)
  }

  handleRemoveItem(item: NotifyStruct): void {
    if (this.subscriptionType !== LimitedSubscriptionType.None) {
      this.removeItemConfirm(item)
    }
  }

  removeItemConfirm(item: NotifyStruct): void {
    this.$confirm({
      title: this.$t('delete_notify_item_warn').toString(),
      okText: this.$t('accept').toString(),
      okType: 'danger',
      cancelText: this.$t('reject').toString(),
      centered: true,
      onOk: () => this.removeItem(item)
    })
  }

  defaultNotifyStruct(): NotifyStruct {
    return cloneDeep({
      guid: Guid.create().toString(),
      time: 0,
      action: this.activationActionMessageStructure()
    })
  }

  onActionItemClick(item: NotifyStruct, newAction: SelectOption): void {
    const type = newAction.value as UserActivationActionType
    const rawNotifies = this.subscriptionConfig.notify

    if (rawNotifies && rawNotifies[item.time] && type === rawNotifies[item.time].type) {
      item.action = rawNotifies[item.time]
    } else {
      if (type === UserActivationActionType.Message) {
        item.action = this.activationActionMessageStructure()
      } else {
        item.action = this.activationActionFlowStructure()
      }
    }
  }

  activationActionMessageStructure(): UserActivationActionMessage {
    return {
      type: UserActivationActionType.Message,
      variants: [ {
        attachments: [],
        text: 'Текст сообщения, который будет отправлен. Задайте собственный.',
        buttons: [],
        remove_previous: false,
        pin: false,
        disable_link_preview: false,
        disable_notify: false,
        send_after: 0,
        remove_after: 0,
        protect_content: false
      } ]
    }
  }

  activationActionFlowStructure(): UserActivationActionFlow {
    return {
      type: UserActivationActionType.Flow,
      flow: this.$store.getters.flowOptions.length ? this.$store.getters.flowOptions[0].value : '',
      output: 0
    }
  }

  setRawNotifies(): any {
    return Object.fromEntries(this.frontendNotifies.slice().map(n => [ n.time, n.action ]))
  }

  setFrontendNotifies(notifies: LimitedSubscriptionNotify | null): Array<NotifyStruct> {
    if (notifies) {
      return Object.entries(notifies).map(([ time, action ]) => ({
        guid: Guid.create().toString(),
        time: Number.parseInt(time),
        action
      }))
    }

    return []
  }

  addRawNotify(): void {
    const newNotify = this.defaultNotifyStruct()
    this.frontendNotifies.push(newNotify)

    this.$nextTick(() => {
      this.$refs[`trigger-${ newNotify.guid }`]![0].$el.scrollIntoView({ behavior: 'smooth' })
    })
  }

  get defaultSubscriptionConfigStruct(): LimitedSubscriptionConfig {
    return {
      from: formatTime('09:00', 'HH:mm:ss'),
      to: formatTime('21:00', 'HH:mm:ss'),
      goodbye: null,
      notify: null,
      issue: null,
    }
  }

  setLocalSubscriptionConfig() {
    const channelConfig = this.$store.state.channelsState.activeChannel!.config

    this.subscriptionType = channelConfig.limited_subscription_type

    if (!channelConfig?.limited_subscription_config) {
      this.subscriptionConfig = cloneDeep(this.defaultSubscriptionConfigStruct)
    } else {
      this.subscriptionConfig = channelConfig.limited_subscription_config
      this.frontendNotifies = this.setFrontendNotifies(channelConfig.limited_subscription_config.notify)
    }
  }

  destroyed(): void {
    this.$store.dispatch('AdminTemplate/hideSaveConfigButton')
  }

  created(): void {
    this.setLocalSubscriptionConfig()
  }
}
