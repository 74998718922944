





































































import { InputSetups } from '@/mixins/input-setups'
import { UserActivationActionFlow, UserActivationActionMessage, UserActivationActionType } from '@/includes/types/PmConfig.types'

import { UseFields } from 'piramis-base-components/src/components/Pi'
import MultiMessageEditorWithMediaInput from 'piramis-base-components/src/components/Pi/fields/NewMultiMessageEditorWithMediaInput/MultiMessageEditorWithMediaInput.vue'
import MessageEditorWithMediaData from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/types'
import { MessageButtons } from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/logic/types'
import { MessageMedia } from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/Media/types'
import { IMessageSettings } from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/Body/logic/types'

import { Component, Mixins, Prop, PropSync, VModel, Watch } from 'vue-property-decorator'
import { cloneDeep, snakeCase } from 'lodash'
import { instanceToPlain, plainToInstance } from 'class-transformer'

@Component({
  components: {
    MultiMessageEditorWithMediaInput
  },
  data() {
    return {
      UserActivationActionType,
      snakeCase
    }
  }
})
export default class TriggerCardAction extends Mixins<UseFields, InputSetups>(UseFields, InputSetups) {
  @VModel() triggerAction!: UserActivationActionFlow | UserActivationActionMessage

  @Prop() actionType!: UserActivationActionType

  @Prop({ type: Boolean }) disabled!:boolean

  @PropSync('actionModalOpen', { type: Boolean }) isActionModalOpen!:boolean

  @Watch('isActionModalOpen')
  onActionModalOpenChange(state:boolean):void {
    if (state) {
      this.prepareMessageToEdit()
    }
  }

  actionMessage: UserActivationActionMessage | null = {} as UserActivationActionMessage

  get variants() {
    if (this.actionMessage) {
      return this.actionMessage.variants.map(v => plainToInstance(MessageEditorWithMediaData, v))
    } else {
      return []
    }
  }

  set variants(value: Array<MessageEditorWithMediaData>) {
    if (this.actionMessage) {
      this.actionMessage.variants = instanceToPlain(value) as Array<IMessageSettings & MessageMedia & MessageButtons>
    }
  }

  handleOpenToEdit():void {
    if (!this.disabled) {
      this.prepareMessageToEdit()
    }
  }

  isModalOpen = false

  prepareMessageToEdit(): void {
    this.actionMessage = cloneDeep(this.triggerAction as UserActivationActionMessage)

    this.isModalOpen = true
  }

  onOkClick() {
    this.triggerAction = this.actionMessage as UserActivationActionMessage
    this.isModalOpen = false
  }

  onCancelClick() {
    this.actionMessage = null
    this.isActionModalOpen = false
  }
}
