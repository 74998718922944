import {
    IMessageSettings
} from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/Body/logic/types'
import {
    MessageMedia
} from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/Media/types'
import {
    MessageButtons
} from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/logic/types'

export type UserActivationAction = UserActivationActionFlow | UserActivationActionMessage

export enum FeedbackGroupType {
    Private = 'PRIVATE',
    Forum = 'FORUM',
    Supergroup = 'SUPERGROUP',
}

export type BoardPmConfig = {
    type: 'common',
    initial_message: Array<IMessageSettings & MessageMedia & MessageButtons> | null
    feedback_group_id: number | null
    feedback_group_type: FeedbackGroupType
    first_activation_action: UserActivationAction | null
    start_activation_action: UserActivationAction | null
    default_activation_action: UserActivationAction | null
    user_auto_reply_actions: Array<UserAutoReplyAction> | null
    commands: Array<UserAutoReplyAction> | null
    allow_answer_without_reply: boolean
    use_forum_status: boolean
    use_admin_tags: boolean
    auto_reply_notify: boolean
    protection: {
        count: number
        interval: number
        message: IMessageSettings & MessageMedia & MessageButtons
    }
}

export enum AutoReplyConditionType {
    Equals = 'Equals',
    Contains = 'Contains',
    StartsWith = 'StartsWith',
    EndsWith = 'EndsWith',
    WordEquals = 'WordEquals',
}

export type EqualsCondition = { type: AutoReplyConditionType.Equals } & AutoReplyConditionBaseFields
export type ContainsCondition = { type: AutoReplyConditionType.Contains } & AutoReplyConditionBaseFields
export type StartsWithCondition = { type: AutoReplyConditionType.StartsWith } & AutoReplyConditionBaseFields
export type EndsWithCondition = { type: AutoReplyConditionType.EndsWith } & AutoReplyConditionBaseFields
export type WordEqualsCondition = { type: AutoReplyConditionType.WordEquals } & AutoReplyConditionBaseFields

export type AutoReplyCondition =
    EqualsCondition
    | ContainsCondition
    | StartsWithCondition
    | EndsWithCondition
    | WordEqualsCondition

export type AutoReplyConditionBaseFields = {
    values: Array<string>
    invert: boolean
}

export type UserAutoReplyAction = {
    conditions: Array<Array<AutoReplyCondition>>
    action: UserActivationActionFlow | UserActivationActionMessage
    guid:string
}

export enum UserActivationActionType {
    Flow = 'Flow',
    Message = 'Message'
}

export type UserActivationActionFlow = {
    type: UserActivationActionType.Flow,
    flow: string
    // temp: default 0
    output: number
}

export type UserActivationActionMessage = {
    type: UserActivationActionType.Message
    variants: Array<IMessageSettings & MessageMedia & MessageButtons>
}
