


































import { SelectOption } from 'piramis-base-components/src/logic/types'

import Vue from 'vue'
import { Component, Emit, Prop } from 'vue-property-decorator'

@Component
export default class TypeSelect extends Vue {
  @Prop({ type: String, required: true }) readonly activeItem!: string

  @Prop({ type: Array, required: true }) readonly dropdownOptions!: Array<SelectOption>

  @Prop() disabled!:boolean

  @Emit()
  settingClick(): void {
  }

  @Emit()
  itemClick(menuItem: SelectOption): SelectOption {
    return menuItem
  }

  get labelForActiveItem():string {
    return this.dropdownOptions.find(o => o.value === this.activeItem)?.label ?? ''
  }
}
